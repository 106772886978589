import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { format } from "date-fns";
import Header from "../Sidebar/Header";

const ViewAdvPay = () => {
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [isnull, setisNull] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const managerData = async () => {
    const managerId = localStorage.getItem("managerId");
    try {
      const response = await fetch(`https://backend.ssdpune.org/api/advpayment/manager/${managerId}`);
      const data = await response.json();
       console.log("Fetched Payments Data:", data);
      if (data.message === 'No payments found for this manager') {
        setisNull(true);
        return;
      }
      setPayments(data);
      setFilteredPayments(data); // Initialize filteredPayments with the fetched data
      setisNull(false);
    } catch (e) {
      console.error(`Failed to fetch data: ${e}`);
    }
  };

  useEffect(() => {
    managerData();
  }, []);

  useEffect(() => {
    filterData(searchTerm, dateRange.startDate, dateRange.endDate);
  }, [searchTerm, dateRange]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setDateRange((prev) => ({ ...prev, startDate: event.target.value }));
  };

  const handleEndDateChange = (event) => {
    setDateRange((prev) => ({ ...prev, endDate: event.target.value }));
  };

  const filterData = (searchTerm, startDate, endDate) => {
    if (isnull) return;
    setisNull(false);

    let filtered = payments.filter((payment) => {
      const eventName = payment.event_name?.toLowerCase() || "";
      const clientName = payment.client_name?.toLowerCase() || "";
      const venue = payment.venue?.toLowerCase() || "";

      return (
        eventName.includes(searchTerm.toLowerCase()) ||
        clientName.includes(searchTerm.toLowerCase()) ||
        venue.includes(searchTerm.toLowerCase())
      );
    });

    if (startDate && endDate) {
      filtered = filtered.filter((payment) => {
        const eventDate = new Date(payment.event_date);
        return eventDate >= new Date(startDate) && eventDate <= new Date(endDate);
      });
    }

    setFilteredPayments(filtered);
  };

  const openPopup = (payment) => {
    setSelectedPayment(payment);
    setShowModal(true);
  };

  const closePopup = () => {
    setShowModal(false);
    setSelectedPayment(null);
  };

  return (
    <>
      <Header />
      <div className="w-full h-screen flex items-center justify-center main-container-for-Addaccount">
        <div className="md:h-[80vh] h-[80vh] md:mt-0 w-[80%]">
          <div className="filter-container">
            <input
              type="text"
              placeholder="Search Client Name, Venue, or Event Name"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
            <span>Start Date:</span>
            <input
              type="date"
              value={dateRange.startDate}
              onChange={handleStartDateChange}
            />
            <span>End Date:</span>
            <input
              type="date"
              value={dateRange.endDate}
              onChange={handleEndDateChange}
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-[30px]">View Customer Payments</h2>
          </div>
          <div className="table-responsive md:w-full overflow-y-auto md:h-[60vh] h-[50vh] md:mt-0">
            <table className="table">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th scope="col">Client Name</th>
                  <th scope="col">Event Name</th>
                  <th scope="col">Event Venue</th>
                  <th scope="col">Event Date</th>
                  <th scope="col">Contact No.</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody className="mt-5" style={{ background: "white", borderRadius: "10px" }}>
                {isnull ? (
                  <tr>
                    <td colSpan="6" className="text-center">No payments found for this manager</td>
                  </tr>
                ) : (
                  filteredPayments.map((payment) => (
                    <tr key={payment._id}>
                      <td>{payment.client_name}</td>
                      <td>{payment.event_name}</td>
                      <td>{payment.venue}</td>
                      <td>{payment.event_date ? format(new Date(payment.event_date), "dd/MM/yyyy") : ""}</td>
                      <td>{payment.contact}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => openPopup(payment)}
                        >
                          View More
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {selectedPayment && (
            <Modal
              show={showModal}
              onHide={closePopup}
              dialogClassName="modal-dialog-centered modal-dialog-responsive"
            >
              <Modal.Header closeButton>
                <Modal.Title>Payment Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <h2>{selectedPayment.event_name}</h2>
                  <p style={{ lineHeight: "35px" }}>
                    Client Name: {selectedPayment.client_name}
                    <br />
                    Event Date: {selectedPayment.event_date ? format(new Date(selectedPayment.event_date), "dd/MM/yyyy") : ""}
                    <br />
                    Number of Guests: {selectedPayment.guest_number}
                    <br />
                    Venue: {selectedPayment.venue}
                    <br />
                    Payment Amount: Rs.{selectedPayment.amount}
                    <br />
                    Advance Payment: Rs.{selectedPayment.adv_payment}
                    <br />
                    Remaining Payment: Rs.{selectedPayment.rem_payment}
                    <br />
                    Payment Method: {selectedPayment.payment_method}
                    <br />
                    Bank Name: {selectedPayment.Bank_Name}
                    <br />
                    Bank Account Number: {selectedPayment.bank_Account_Number}
                    <br />
                    Payment Date: {selectedPayment.payment_date}
                    <br />
                    Payment Time: {selectedPayment.payment_time}
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closePopup}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewAdvPay;
