import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Header from "../Sidebar/Header";
import { Alert } from "react-bootstrap";
import { Link } from 'react-router-dom';

function AdvancePaymentCus() {
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const inquiryDataFromUrl = queryParams.get("inquiryData");
  const inquiryData = inquiryDataFromUrl ? JSON.parse(inquiryDataFromUrl) : null;

  const [totalAmount, setTotalAmount] = useState("");
  const [advancePayment, setAdvancePayment] = useState("");
  const [remainingAmount, setRemainingAmount] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [bankNames, setBankNames] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [transaction, setTransaction] = useState("");
  const [submittedTo, setSubmittedTo] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(inquiryData || null);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventVenue, setEventVenue] = useState("");
  const [quoatationAmount, setQuoatationAmount] = useState("");

  const managerId = localStorage.getItem('managerId');


  const fetchQuotation = async() =>{
    try {
      const res = await axios.get(`https://backend.ssdpune.org/api/quotationinfo/customer/${inquiryData._id}`)
    if(res.status === 200){

      setQuoatationAmount(res.data.Total_Amount);
    }
    if(res.status === 202){

      setQuoatationAmount(0);
    }
    } catch (error) {
      console.log(error)
    }
  }
  // Fetch bank names
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await axios.get("https://backend.ssdpune.org/api/allbanks");
        setBankNames(response.data);
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };

    fetchBanks();
    fetchQuotation()
  }, []);
  
  
  // Fetch client data if inquiryData is null
  useEffect(() => {
    if (!inquiryData) {
      const fetchClients = async () => {
        try {
          const response = await axios.get("https://backend.ssdpune.org/api/enquiry");
          setClientList(response.data);
        } catch (error) {
          console.error("Error fetching client list:", error);
        }
      };

      fetchClients();
    }
  }, [inquiryData]);
  
  const handleTotalAmountChange = (event) => {
    const value = parseFloat(event.target.value);
    if (value < 0) {
      setErrors((prevErrors) => ({ ...prevErrors, totalAmount: "Total amount cannot be negative." }));
    } else {
      setTotalAmount(value);
      setErrors((prevErrors) => ({ ...prevErrors, totalAmount: "" }));
      if (advancePayment > value) {
        setErrors((prevErrors) => ({ ...prevErrors, advancePayment: "Advance payment cannot exceed total amount." }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, advancePayment: "" }));
        setRemainingAmount(value - advancePayment);
      }
    }
  };

  const handleAdvancePaymentChange = (event) => {
    const value = parseFloat(event.target.value);
    if (value < 0) {
      setErrors((prevErrors) => ({ ...prevErrors, advancePayment: "Advance payment cannot be negative." }));
    } else if (value > totalAmount) {
      setErrors((prevErrors) => ({ ...prevErrors, advancePayment: "Advance payment cannot exceed total amount." }));
    } else {
      setAdvancePayment(value);
      setErrors((prevErrors) => ({ ...prevErrors, advancePayment: "" }));
      setRemainingAmount(totalAmount - value);
    }
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, paymentMethod: "" }));
  };

  const handleBankSelect = (event) => {
    const selectedBankName = event.target.value;
    setSelectedBank(selectedBankName);
    const selectedBankObj = bankNames.find(bank => bank.Bank_Name === selectedBankName);
    setAccountNumber(selectedBankObj ? selectedBankObj.Account_Number : '');
    setErrors((prevErrors) => ({ ...prevErrors, selectedBank: "" }));
  };

  const handleClientSelect = async (event) => {
    const selectedClientId = event.target.value;
    const selectedClientObj = clientList.find(client => client._id === selectedClientId);
    setSelectedClient(selectedClientObj);
  
    // Set event details when a client is selected
    setEventName(selectedClientObj?.event_name || "");
    setEventDate(selectedClientObj?.event_date || "");
    setEventVenue(selectedClientObj?.event_venue || "");
  
    try {
      const res = await axios.get(`https://backend.ssdpune.org/api/quotationinfo/customer/${selectedClientId}`);
      
      if (res.status === 200) {
        const amount = res.data.Total_Amount;
  
        setQuoatationAmount(amount);
  
        // Check if the quotation amount is not found or is null
        if (amount == null || amount <= 0) {
          alert("Quotation not found or amount is invalid.");
        }
      } else if (res.status === 202) {
        setQuoatationAmount(0);
        alert("Quotation not found for the selected client.");
      }
    } catch (error) {
      console.error("Error fetching quotation info:", error);
      alert("Quotation not found or selected client.");
    }
  };

  const currentDate = new Date();
  const currentTime = currentDate.toLocaleTimeString("en-US", {
    hour12: false,
  });

  const validateForm = () => {
    const newErrors = {};

    if (!totalAmount) {
      newErrors.totalAmount = "This field is mandatory.";
    }

    if (!advancePayment && advancePayment !== 0) {
      newErrors.advancePayment = "This field is mandatory.";
    }

    if (!selectedBank) {
      newErrors.selectedBank = "This field is mandatory.";
    }

    if (!paymentMethod) {
      newErrors.paymentMethod = "This field is mandatory.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (!validateForm()) {
      return;
    }

    const additionalPaymentDetails = {
      ...(paymentMethod === "cheque" && { cheque_number: chequeNumber }),
      ...(paymentMethod === "cash" && { whome_to_submit: submittedTo }),
      ...(paymentMethod === "netbanking" && { transaction_id: transaction }),
    };

    const data = {
      managerId,
      clientId: selectedClient?._id || '',
      client_name: selectedClient?.customer_name || '',
      contact: selectedClient?.contact || '',
      event_name: eventName,
      event_date: eventDate,
      venue: selectedClient?.event_venue || '',
      guest_number: selectedClient?.guest_quantity || '',
      event_requirement: selectedClient?.event_requirement || '',
      amount: totalAmount,
      adv_payment: advancePayment,
      rem_payment: remainingAmount,
      payment_method: paymentMethod,
      Bank_Name: selectedBank,
      bank_Account_Number: accountNumber,
      payment_date: currentDate.toISOString().split("T")[0],
      payment_time: currentTime,
      ...additionalPaymentDetails,
    };

    axios
      .post("https://backend.ssdpune.org/api/advpayment", data)
      .then(() => {
        alert("Customer payment successfully.");
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setAlertMessage("Failed to save customer payment.");
        setAlertVariant("danger");
      });
  };

  return (
    <>
      <Header />
      <div className="w-full h-screen flex items-center justify-center main-container-for-Addaccount overflow-y-auto">
        <div className="md:h-[80vh] h-[80vh] md:w-[50%]">
        <div className="flex">
            <Link to={'/eventmanager/viewadvpay'}>
              <button className="btn btn-primary mr-4 mb-4">View Advance Payment</button>
            </Link>
          </div>
          <h2 className="text-[30px] pl-[1em]">Advance Payment Form</h2>
          {alertMessage && (
            <Alert variant={alertVariant}>{alertMessage}</Alert>
          )}

          {inquiryData ? (
            <>
              <div className="row mb-2">
                <div className="col px-5">
                  <div className="mb-3">
                    <label className="form-label">Customer Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={inquiryData.customer_name || ''}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col px-5">
                  <div className="mb-3">
                    <label className="form-label">Contact</label>
                    <input
                      type="text"
                      className="form-control"
                      value={inquiryData.contact || ''}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col px-5">
                  <div className="mb-3">
                    <label className="form-label">Event Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={inquiryData.event_name || eventName}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col px-5">
                  <div className="mb-3">
                    <label className="form-label">Event Date</label>
                    <input
                      type="text"
                      className="form-control"
                      value={inquiryData.event_date || eventDate}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col px-5">
                  <div className="mb-3">
                    <label className="form-label">Venue</label>
                    <input
                      type="text"
                      className="form-control"
                      value={inquiryData.event_venue || ''}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col px-5">
                  <div className="mb-3">
                    <label className="form-label">Quoatation Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      value={quoatationAmount}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>

            
         
            <div className="col px-5">
            <div className="mb-4">
              <label className="form-label">Select Client:</label>
              <select
                className="form-control"
                value={selectedClient ? selectedClient._id : ''}
                onChange={handleClientSelect}
              >
                <option value="">Select a client</option>
                {clientList.map((client) => (
                  <option key={client._id} value={client._id}>
                    {client.customer_name} - {client.contact}
                  </option>
                ))}
              </select>
            </div>
            </div>


            <div className="row mb-2">
                <div className="col px-5">
                  <div className="mb-3">
                    <label className="form-label">Event Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ eventName}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col px-5">
                  <div className="mb-3">
                    <label className="form-label">Event Date</label>
                    <input
                      type="text"
                      className="form-control"
                      value={eventDate}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col px-5">
                  <div className="mb-3">
                    <label className="form-label">Venue</label>
                    <input
                      type="text"
                      className="form-control"
                      value={eventVenue}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col px-5">
                  <div className="mb-3">
                    <label className="form-label">Quotation Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      value={quoatationAmount}
                      
                    />
                  </div>
                </div>
              </div>
                </>
          )}

          {/* Remaining form fields for payment and bank selection */}

          <div className="row mb-2">
            <div className="col px-5">
              <div className="mb-3">
                <label className="form-label">Total Amount:<span style={{ color: "red" }}>*</span></label>
                <input
                  type="number"
                  className={`form-control ${errors.totalAmount ? "is-invalid" : ""}`}
                  value={totalAmount}
                  onChange={handleTotalAmountChange}
                />
                {errors.totalAmount && (
                  <div className="text-danger">{errors.totalAmount}</div>
                )}
              </div>
            </div>
            <div className="col px-5">
              <div className="mb-3">
                <label className="form-label">Advance Payment:<span style={{ color: "red" }}>*</span></label>
                <input
                  type="number"
                  className={`form-control ${errors.advancePayment ? "is-invalid" : ""}`}
                  value={advancePayment}
                  onChange={handleAdvancePaymentChange}
                />
                {errors.advancePayment && (
                  <div className="text-danger">{errors.advancePayment}</div>
                )}
              </div>
            </div>

          </div>

          <div className="row mb-2">


            <div className="col px-5">
              <div className="mb-3">
                <label className="form-label">Remaining Amount:</label>
                <input
                  type="number"
                  className={`form-control ${errors.remainingAmount ? "is-invalid" : ""}`}
                  value={remainingAmount}
                  readOnly
                />
                {errors.remainingAmount && (
                  <div className="text-danger">{errors.remainingAmount}</div>
                )}
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col px-5">
              <div className="mb-3">
                <label className="form-label">Payment Method:<span style={{ color: "red" }}>*</span></label>
                <select
                  className={`form-control ${errors.paymentMethod ? "is-invalid" : ""}`}
                  value={paymentMethod}
                  onChange={handlePaymentMethodChange}
                >
                  <option value="">Select Payment Method</option>
                  <option value="cheque">Cheque</option>
                  <option value="cash">Cash</option>
                  <option value="netbanking">Net Banking</option>
                </select>
                {errors.paymentMethod && (
                  <div className="text-danger">{errors.paymentMethod}</div>
                )}
              </div>
            </div>

            <div className="col px-5">
              <div className="mb-3">
                {paymentMethod === "cheque" && (
                  <>
                    <div className="mb-3">
                      <label className="form-label">Cheque Number:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={chequeNumber}
                        onChange={(e) => setChequeNumber(e.target.value)}
                      />
                    </div>
                  </>
                )}

                {paymentMethod === "cash" && (
                  <>
                    <div className="mb-3">
                      <label className="form-label">
                        Name to Whom Submitted:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={submittedTo}
                        onChange={(e) => setSubmittedTo(e.target.value)}
                      />
                    </div>
                  </>
                )}

                {paymentMethod === "netbanking" && (
                  <>
                    <div className="mb-3">
                      <label className="form-label">Transaction Id:</label>
                      <input
                        type="text"
                        className="form-control"
                        value={transaction}
                        onChange={(e) => setTransaction(e.target.value)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col px-5">
              <div className="mb-3">
                <label className="form-label">Select Bank:<span style={{ color: "red" }}>*</span></label>
                <select
                  className={`form-control ${errors.selectedBank ? "is-invalid" : ""}`}
                  value={selectedBank}
                  onChange={handleBankSelect}
                >
                  <option value="">Select Bank</option>
                  {bankNames.map((bank) => (
                    <option key={bank._id} value={bank.Bank_Name}>
                      {bank.Bank_Name}
                    </option>
                  ))}
                </select>
                {errors.selectedBank && (
                  <div className="text-danger">{errors.selectedBank}</div>
                )}
              </div>
            </div>

            <div className="col px-5">
              <div className="mb-3">
                <label className="form-label">Account Number:</label>
                <input
                  type="number"
                  className="form-control"
                  value={accountNumber}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col px-5">
              <div className="mb-3">
                <label className="form-label">Date of Transaction:</label>
                <input
                  type="date"
                  className="form-control"
                  value={currentDate.toISOString().split("T")[0]}
                  readOnly
                />
              </div>
            </div>

            <div className="col px-5">
              <div className="mb-3">
                <label className="form-label">Time of Transaction:</label>
                <input
                  type="time"
                  className="form-control"
                  value={currentTime}
                  readOnly
                />
              </div>
            </div>
          </div>

          <button className="manager-btn ms-4 mb-3" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default AdvancePaymentCus;
